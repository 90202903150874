import '@/inc/module/SearchFieldMulti/searchfield_multi.scss';
import { EventDispatcher, getOffset, hideElement, isAbortError, isDesktopView, isMobileView, isShowresultPage, l, logEvent, nextElement, qsa, showElement, slideDown, slideUp, storedItemCreate, storedItemRead } from '@utils/toolbox';
import { eventCategories, events } from '@js/utils/event';
import { Base64 } from '@js/plugins/base64';
import { ConnectApi } from '@js/plugins/connectApi';
import { trackEvent } from '@utils/googleDataLayer';


// Local helpers
function hasAncestor (child, parent) {
	let currentNode = child;
	try {
		do {
			if (currentNode == parent) {
				return true;
			}
			// One step up in the tree
			currentNode = currentNode.parentNode;
		} while (currentNode);
	} catch (err) {
		window.onerror(err.toString(), '?', 0, 0);
	}

	return false;
}

export class SearchFieldMulti {
	constructor (opts) {
		Handlebars.registerPartial('destinationPartial', Handlebars.templates.searchfield_destination);
		this.tabIndex = opts.tabIndex ?? 0;

		// Dom references
		this.inputElement = opts.inputElem;
		this.searchBox = opts.searchBox ?? {};
		this.variation = (this.searchBox.variation) ? this.searchBox.variation : 'default';
		this.isDefaultSearchBox = this.searchBox && this.variation === 'default';
		this.isPriceHunterPage = this.searchBox && this.variation !== 'default' && this.searchBox.variation === 'priceHunter';
		this.field = this.inputElement.closest('.SearchFieldMulti');
		this.overlay = document.getElementById('abSearchBox');
		this.fieldModal = this.inputElement.closest('.SearchFieldMulti__modal');
		this.moduleWrapper = this.inputElement.closest('.SearchFieldMulti__wrapper');
		this.fieldScroller = this.moduleWrapper.querySelector('.SearchFieldMulti__field__scroller');
		this.checkedIatasContainer = this.moduleWrapper.querySelector('.SearchFieldMulti__checked_iatas_container');
		this.inputElementName = this.inputElement.name;
		this.autocompleteContainer = this.fieldModal.querySelector('.SearchFieldMulti__autocomplete_container');
		this.scrollWrapper = this.autocompleteContainer.querySelector('.SearchFieldMulti__scroll_wrapper');
		this.applyContainer = this.autocompleteContainer.querySelector('.SearchFieldMulti__apply_container');
		this.clearInputBtn = this.moduleWrapper.querySelector('.SearchFieldMulti__clear_btn');
		this.applyBtn = this.moduleWrapper.querySelector('.SearchFieldMulti__apply_btn');
		this.toMostPopular = {};
		this.searchFieldIndex = 0;
		this.inputElementLostFocus = true;
		this.closedModalSince = true;
		this.alreadyShowedMostPopular = false;

		this.translations = {
			previousSearches: l('searchBox.PreviousSearches'),
			clearHistory: l('searchBox.clearHistory'),
			airports: l('global.simplePhrases.airport'),
			countriesPH: l('pricehunter.options.countries'),
			allAirports: l('module.searchField.allAirports'),
			countries: l('module.searchfield.countries'),
			regions: l('module.searchfield.regions'),
			citiesAndAirports: l('module.searchfield.cities_and_airports'),
			noResults: l('module.searchfield.no_results'),
			mostPopular: l('module.searchfield.most_popular'),
			priceHunterLink: l('global.navigation.priceHunterLink').toLowerCase(),
			notSure: l('module.searchfield.not_sure_msg'),
			tryPriceHunter: l('module.searchfield.try_pricehunter'),
			otherPossibleResults: l('module.searchfield.other_possible_results'),
			areULooking: l('module.searchfield.are_u_looking'),
			reportMissingAiport: l('infopage.sidebar.reportMissingAirports')
		};

		const bound = this.inputElement.dataset.bound;

		this.direction = (bound === 'from') ? 'FROM' : 'TO';
		this.oppositeDirection = (bound === 'from') ? 'TO' : 'FROM';
		this.isMultiModeAllowed = false;
		this.DEFAULT_IATAS_LIMIT = 6;

		// State
		this.searchRequest = null;
		this.keystrokesCount = 0;
		this.searchText = '';

		const searchLog = {};
		Object.defineProperty(this, 'searchResults', {
			get () {
				if (!this.searchText && this.searchBox.searchFields) {
					return this.fromOrToFocus == 'from' ? this.fromMostPopular : this.toMostPopular[this.getFromIata()];
				}
				return searchLog[this.searchText];
			},
			set (v) {
				searchLog[v.searchText] = v;
				this.render();
			}
		});

		this.defineReactiveProperty('initialAirports', [], () => {
			// if no previously searched airports in history, use the most popular instead
			if (this.initialAirports.length) {
				this.render();
			}
		});

		this.defineReactiveProperty('checkedIatas', [], () => {
			this.markCheckedIatas();
			if (!this.isPriceHunterPage) {
				this.renderCheckedIatas();
			}
			if (!this.isActive) {
				return;
			}

			this.renderCheckBoxes();
			this.renderApply();
			this.adjustHeight();
		});

		this.defineReactiveProperty('expandedGroup', null, () => {
			if (this.expandedGroup !== null && this.isMobile()) {
				this.render();
			}
		});
		this.defineReactiveProperty('iatasLimit', this.DEFAULT_IATAS_LIMIT, () => {
			if (this.iatasLimit > 1 && !this.isPriceHunterPage) {
				// Enable multiple choices
				this.autocompleteContainer.classList.add('SearchFieldMulti__autocomplete_container--multi');
			} else {
				// Disable multiple choices
				this.autocompleteContainer.classList.remove('SearchFieldMulti__autocomplete_container--multi');
			}
		});

		// Timers
		this.keyboardDetectInterval = null;

		// Event handlers
		/*
			 this.touchMoveHandler = (event) => {
			 if (!this.autocomleteContainerHidden && hasAncestor(event.target, this.moduleWrapper))
			 return;

			 event.preventDefault();
			 };
			 */

		// template-related state variables
		this.isActive = false;
		this.autocompleteContainerHidden = true;
		this.fieldModalHidden = true;
		this.isInputScrollingEnabled = () => true;

		// Boot it up!
		this.bindEvents();
		this.renderCheckedIatas();
		this.events();

		this.inputElement.tabIndex = this.tabIndex;
	}

	events () {
		this.scrollWrapper.addEventListener('click', (event) => {
			const target = event.target;
			// Clear stored iatas action
			if (target.classList.contains('SearchFieldMulti__clearBtn')) {
				event.stopPropagation();

				this.storeUserIatas({});
				this.initialAirports = [];
				this.inputElement.focus();
			}
		});
		// TODO: YES?
		const changeEventToListen = this.getIatasChangedEventName(this.oppositeDirection);
		EventDispatcher.addEventListener(changeEventToListen, data => this.updateMaxIatasBasedOnOppositeField(data.iatasNb));

		const setIataEventToListen = this.getSetIatasEventName(this.direction);
		EventDispatcher.addEventListener(setIataEventToListen, data => this.setCheckedIatasOfVariation(data.iatas, data.variation));
	}

	fetchHistory () {
		const previousSearches = this.getStoredUserIatasSpecific(this.inputElementName).reverse();
		const iatas = previousSearches.map(x => x.iata);
		if (!iatas.length) {
			return;
		}

		this.cancelPendingReq();
		this.searchRequest = ConnectApi('IataAutocomplete', 'searchByIatas', [iatas]).then((results) => {
			this.initialAirports = results;
		});
	}

	initIatasMax () {
		const oppositeIatasNb = document.querySelectorAll(`.SearchFieldMulti--${this.oppositeDirection.toLowerCase()} .SearchFieldMulti__checked_iata`).length;

		this.updateMaxIatasBasedOnOppositeField(oppositeIatasNb);
	}

	bindEvents () {
		const forceFocus = () => {
			if (!this.isActive) {
				this.inputElement.blur();
				this.inputElement.focus();
			}
		};

		this.inputElement.addEventListener('focusout', (e) => {
			this.inputElementLostFocus = true;
		});


		this.inputElement.addEventListener('focus', (e) => {
			if (this.inputElementLostFocus) {
				this.inputElement.select();
				this.activate();
				this.openModal(this.moduleWrapper);

				this.inputElementLostFocus = false;

				this.fromOrToFocus = e.srcElement.id;
				this.searchFieldIndex = e.target.tabIndex > 0 ? Math.ceil(e.target.tabIndex / 2) - 1 : 0;
				this.searchAll();

				this.fetchHistory();
				this.initIatasMax();

				this.renderApply();
				this.adjustHeight();
				e.stopPropagation();
			}
		});

		// input
		this.inputElement.addEventListener('input', () => {
			forceFocus(); // Sometimes the user might manage to focus before the focus handler is registered.

			this.searchText = this.inputElement.value;
			this.expandedGroup = null;
			this.searchAll();
			this.keystrokesCount++;
		});

		// clear input btn
		this.clearInputBtn.addEventListener('click', (e) => {
			if (this.inputElement.value.length || this.checkedIatas.length) {
				this.setCheckedIatas([]);
				this.inputElement.value = '';
				this.searchText = '';
				this.render();
				this.inputElement.focus();
			} else {
				e.stopPropagation();
				this.cancel();
			}
		});

		this.inputElement.addEventListener('keydown', (event) => {
			let hoveredRow = null;
			switch (event.key) {
				case 'Enter':
					if (this.checkedIatas.length && !this.inputElement.value.length) {
						this.apply();
					}

					hoveredRow = this.scrollWrapper.querySelector('.SearchFieldMulti__row--hovered');
					if (!hoveredRow) {
						return;
					}
					// Has iata -> is airport or metro
					if (this.isDestinationRow(hoveredRow)) {
						// Select row
						this.destinationSelected(hoveredRow);

						// Focus the next input
						EventDispatcher.dispatch('targetNextInput', this.inputElement);
					} else if (this.isGroupRow(hoveredRow)) {
						// Expand country or region
						this.toggleExpand(hoveredRow);
					}
					break;

				case 'ArrowLeft':
				case 'ArrowRight':
					hoveredRow = this.scrollWrapper.querySelector('.SearchFieldMulti__row--hovered');
					if (!hoveredRow?.length) {
						return;
					}
					if (this.isGroupRow(hoveredRow)) {
						// Expand country Or region
						event.preventDefault();
						this.toggleExpand(hoveredRow);
					}

					break;

				case 'ArrowUp':
				case 'ArrowDown':
				{
					event.preventDefault();
					const rows = qsa('.SearchFieldMulti__row', this.scrollWrapper);
					if (!rows.length) {
						return;
					}

					hoveredRow = this.scrollWrapper.querySelector('.SearchFieldMulti__row--hovered');

					if (!hoveredRow) {
						hoveredRow = rows[0];
						hoveredRow.classList.add('SearchFieldMulti__row--hovered');
						return;
					}

					let hoveredIndex = rows.indexOf(hoveredRow);

					// Determine target row index
					hoveredIndex = (event.key === 'ArrowUp') ? Math.max(0, hoveredIndex - 1) : Math.min(rows.length - 1, hoveredIndex + 1);

					// Update hovered row
					hoveredRow.classList.remove('SearchFieldMulti__row--hovered');
					const target = rows[hoveredIndex];
					target.classList.add('SearchFieldMulti__row--hovered');

					// Scroll to target
					const topSpace = 5;
					const offset = target.offsetTop - this.scrollWrapper.offsetTop - topSpace;
					this.scrollWrapper.scrollTo({ top: offset, behavior: 'smooth' });
					break;
				}
				case 'Tab':
					if (this.autocompleteContainerHidden) {
						this.apply();
						return;
					}
					hoveredRow = this.scrollWrapper.querySelector('.SearchFieldMulti__row--hovered');
					if (!hoveredRow) {
						this.closeAutocompleteContainer();
						return;
					}
					if (this.isDestinationRow(hoveredRow)) {
						this.destinationSelected(hoveredRow);
					}
					break;

				case 'Backspace':
					if (this.inputElement.value.length === 0) {
						this.checkedIatas = this.checkedIatas.slice(0, -1);
					}
					break;

				default:
					if (this.checkedIatas.length == 1 && !this.moduleWrapper.classList.contains('multi-mode')) {
						this.checkedIatas = this.checkedIatas.slice(0, -1);
					}
			}
		});

		// Apply / Cancel
		this.applyContainer.querySelector('.SearchFieldMulti__cancel_btn').addEventListener('click', () => {
			this.cancel(true);
		});

		this.fieldModal.querySelectorAll('.SearchFieldMulti__apply_btn').forEach((btn) => {
			btn.addEventListener('click', (e) => {
				e.preventDefault();

				this.apply();
			});
		});

		this.scrollWrapper.addEventListener('click', (e) => {
			// Resolve click target
			let captured = false;
			let target = e.target;
			do {
				if (target.classList && target.classList.contains('SearchFieldMulti__checkbox')) {
					if (!this.moduleWrapper.classList.contains('multi-mode')) {
						this.moduleWrapper.classList.add('multi-mode');
					}

					if (target.classList.contains('SearchFieldMulti__checkbox--checked')) {
						this.boxUnchecked(target);
					} else {
						this.boxChecked(target);
					}

					this.inputElement.focus();
					this.scrollFieldToEnd();

					captured = true;
				} else if (target?.classList?.contains?.('destination')) {
					this.destinationSelected(target);
					captured = true;
				} else if (target.classList.contains('SearchFieldMulti__group_header')) {
					this.toggleExpand(target);
					captured = true;
				} else if (target.classList.contains('SearchFieldMulti__section_header')) {
					if (isMobileView() && target.classList.contains('expanded')) {
						// Go back to previous view
						this.expandedGroup = null;
						this.render();
						this.refocus();
					}
					captured = true;
				}

				if (captured) {
					e.stopPropagation();
					return;
				}

				target = target.parentNode;
			} while (target != null && target != e.currentTarget);
		});

		this.checkedIatasContainer.addEventListener('click', (e) => {
			let target = e.target;
			do {
				if (target.classList && target.classList.contains('SearchFieldMulti__checked_iata--clear')) {
					e.stopPropagation();
					target = target.parentNode;

					const iata = target.dataset.iata;
					const isMetro = target.dataset.metro === '1';
					this.isInputScrollingEnabled = (function () {
						let preventedOnce = false;
						return () => preventedOnce || !(preventedOnce = true);
					}());
					this.checkedIatas = this.checkedIatas.filter(checked => checked.isMetro !== isMetro || checked.iata != iata);
					const event = new Event('change', { bubbles: true });
					this.inputElement.dispatchEvent(event);

					if (this.isDefaultSearchBox) {
						this.searchBox.updateLegs();
					}

					this.dispatchIatasChangedEvent();
				}
				target = target.parentNode;
			} while (target != null && target != e.currentTarget);
		});

		this.scrollWrapper.addEventListener('mouseenter', () => {
			this.scrollWrapper.querySelector('.SearchFieldMulti__row--hovered')?.classList?.remove?.('SearchFieldMulti__row--hovered');
		});

		this.scrollWrapper.addEventListener('mouseleave', () => {
			this.scrollWrapper.querySelector('.SearchFieldMulti__row--hovered')?.classList?.remove?.('SearchFieldMulti__row--hovered');
		});
	}

	searchAll () {
		if (this.searchResults) {
			this.render();
			return;
		}
		const searchText = this.searchText;
		this.cancelPendingReq();

		const fromIata = this.getFromIata();

		if (!searchText) {
			this.searchRequest = ConnectApi('IataAutocomplete', 'getMostPopular', [this.fromOrToFocus, fromIata]).then((results) => {
				if (this.fromOrToFocus == 'from') {
					this.fromMostPopular = results;
				} else {
					this.toMostPopular[fromIata] = results;
				}
				this.render();
			}).catch((error) => {
				if (!isAbortError(error)) {
					throw error;
				}
			});
		} else {
			this.searchRequest = ConnectApi('IataAutocomplete', 'searchAll', this.searchText).then((results) => {
				results.searchText = searchText;
				this.searchResults = results;
			}).catch((error) => {
				if (!isAbortError(error)) {
					throw error;
				}
			});
		}
	}

	cancelPendingReq () {
		this.searchRequest?.abort?.();
	}

	markCheckedIatas () {
		const markChecked = (destination) => {
			const matchesMetro = !!this.checkedIatas.find(checked => (checked.isMetro && checked.iata === destination.metropolitan_area_iata));
			if (matchesMetro) {
				destination.checked = matchesMetro;
				destination.children.forEach(child => child.checked = true);
			} else {
				const matchesAirport = !!this.checkedIatas.find(checked => (!checked.isMetro && checked.iata === destination.iata));
				destination.checked = matchesAirport;
				// mark children if has any
				if (destination.children) {
					destination.children.forEach(markChecked);
				}
			}
		};
		if (this.searchResults) {
			this.searchResults.airports.forEach(markChecked);
			this.searchResults.countries.forEach(country => country.children.forEach(markChecked));
			this.searchResults.regions.forEach(region => region.children.forEach(markChecked));
			this.searchResults.nearby_cities.forEach(region => region.children.forEach(markChecked));
		}
		this.initialAirports.forEach(markChecked);
	}

	destinationSelected (targetElement) {
		const iata = targetElement.dataset.iata;
		const isMetro = targetElement.dataset.type === 'metro';
		const origin = this.classifyDestination(targetElement);
		this.selectIata(iata, isMetro, origin);

		if (targetElement.closest('.SearchFieldMulti--from')) {
			logEvent(events.editsearchDestinationFrom, eventCategories.search, iata);
		} else if (targetElement.closest('.SearchFieldMulti--to')) {
			logEvent(events.editsearchDestinationTo, eventCategories.search, iata);
		}
	}

	selectHeighligtedIata () {
		const hoveredRow = this.scrollWrapper.querySelector('.SearchFieldMulti__row--hovered');
		if (!hoveredRow) {
			return;
		}
		// Has iata -> is airport or metro
		if (this.isDestinationRow(hoveredRow)) {
			// Select row
			this.destinationSelected(hoveredRow);
		}
	}

	selectIata (iata, isMetro, origin = 'AIRPORT') {
		if (this.checkedIatas.length > this.iatasLimit && !this.isPriceHunterPage) {
			return;
		}

		const dest = this.findIata(iata, isMetro);

		this.inputElement.dataset.iata = iata;
		this.inputElement.dataset.metro = isMetro;
		this.inputElement.dataset.resultRow = dest;
		// @todo set children iatas
		this.inputElement.childreniatas = [];

		this.appendUserIataToStore(iata, isMetro ? 1 : '');

		const text = this.buildText(dest);

		if (this.isPriceHunterPage && isMetro) {
			const children = [];
			dest.children.forEach((child) => {
				children.push({
					iata: child.iata,
					isMetro: false,
					text: child.text,
					origin
				});
			});
			this.inputElement.childreniatas = children;
		}

		this.checkedIatas = [
			{
				iata,
				isMetro,
				text,
				origin
			}
		];

		this.apply();
		this.trackCheckedIata(origin);
	}

	boxChecked (targetElement) {
		if (this.checkedIatas.length > this.iatasLimit && !this.isPriceHunterPage) {
			return;
		}
		const { parentElement } = targetElement;
		const iata = parentElement.dataset.iata;
		const isMetro = parentElement.dataset.type === 'metro';
		const origin = this.classifyDestination(parentElement);
		this.checkIata(iata, isMetro, origin);
		this.inputElement.value = '';
	}

	boxUnchecked (targetElement) {
		const { parentElement } = targetElement;
		const iata = parentElement.dataset.iata;
		const isMetro = parentElement.dataset.type === 'metro';
		this.uncheckIata(iata, isMetro);
	}

	checkIata (iata, isMetro, origin = 'AIRPORT') {
		const dest = this.findIata(iata, isMetro);
		const text = this.buildText(dest);
		let checkedIatas = this.checkedIatas.concat([
			{
				iata,
				isMetro,
				text,
				origin
			}
		]);

		this.appendUserIataToStore(iata, dest.metropolitan_area_iata ? 1 : '');

		// If the rendered view is not the initial view (a.k.a. complete children) and all children selected
		// then we want to replace all the checked children with parent provided the parent is a metro
		const allSiblingsChecked = dest.parent && dest.parent.type === 'metro' && dest.parent.children.every((sibling) => {
			return checkedIatas.some(checked => !checked.isMetro && checked.iata === sibling.iata);
		});
		if (allSiblingsChecked) {
			const text = this.buildText(dest.parent);
			checkedIatas.push({
				iata: dest.parent.metropolitan_area_iata,
				isMetro: true,
				text,
				origin
			});
			checkedIatas = checkedIatas.filter(checked => checked.isMetro || dest.parent.children.includes(checked.iata));
		}

		if (isMetro) {
			// Uncheck children
			const childrenIatas = dest.children.map(child => child.iata);
			checkedIatas = checkedIatas.filter(checked => checked.isMetro || !childrenIatas.includes(checked.iata));
		}

		const event = new Event('change', { bubbles: true });
		this.inputElement.dispatchEvent(event);

		this.checkedIatas = checkedIatas;

		this.trackCheckedIata(origin);
	}

	uncheckIata (iata, isMetro, origin = 'AIRPORT') {
		const dest = this.findIata(iata, isMetro);
		let checkedIatas = this.checkedIatas.filter(checked => checked.isMetro !== isMetro || checked.iata !== iata);
		if (isMetro) {
			const childrenIatas = dest.children.map(child => child.iata);
			checkedIatas = checkedIatas.filter(iata => !childrenIatas.includes(iata));
		} else if (dest.parent && dest.parent.type === 'metro') { // Parent is a metro
			const parentIata = dest.parent.metropolitan_area_iata;
			// Uncheck parent metro iata and add all other sibling iatas if not already added
			if (checkedIatas.find(checked => checked.isMetro && checked.iata === parentIata) !== undefined) {
				checkedIatas = checkedIatas.filter(checked => !checked.isMetro || checked.iata !== parentIata);
				const siblingIatas = dest.parent.children
					.map((child) => {
						const text = this.buildText(child);
						return {
							iata: child.iata,
							isMetro: (child.type === 'metro'),
							text,
							origin
						};
					})
					.filter(sibling => sibling.isMetro !== isMetro || sibling.iata !== iata);
				checkedIatas = checkedIatas.concat(siblingIatas);
			}
		}

		const event = new Event('change', { bubbles: true });
		this.inputElement.dispatchEvent(event);

		this.checkedIatas = checkedIatas;
	}

	renderCheckedIatas () {
		const htmlDivs = this.checkedIatas.reduce((html, checked) => {
			return html + `<div class="SearchFieldMulti__checked_iata" data-iata="${checked.iata}" data-metro="${checked.isMetro ? 1 : 0}">
							${checked.isMetro ? `<span class="all-airports"> ${l('module.searchField.allAirports')}, </span>` : ''}
							<span> ${checked.text} </span>
							<div class="SearchFieldMulti__checked_iata--clear"><i class="icon icon-cross"></i></div>
						</div>`;
		}, '');
		this.checkedIatasContainer.innerHTML = htmlDivs;
	}

	buildText (dest) {
		if (dest.metropolitan_area_iata) {
			return dest.metropolitan_area_name;
		}
		return dest.airport;
	}

	findIata (iata, isMetro) {
		const recursiveFind = (dest, parent = null) => {
			if (Array.isArray(dest)) {
				for (let i = 0; i < dest.length; i++) {
					const needle = recursiveFind(dest[i], parent);
					if (needle) {
						return needle;
					}
				}
			}
			const matchesAirport = !isMetro && dest.iata === iata;
			const matchesMetro = isMetro && dest.metropolitan_area_iata === iata;
			if (matchesAirport || matchesMetro) {
				dest.parent = parent;
				return dest;
			} else if (dest.children) {
				return recursiveFind(dest.children, dest);
			}
		};

		let allDestinations = [];
		if (this.searchResults) {
			allDestinations = allDestinations.concat(this.searchResults.airports, this.searchResults.countries, this.searchResults.regions, this.searchResults.nearby_cities);
		}
		allDestinations = allDestinations.concat(allDestinations, this.initialAirports);

		return recursiveFind(allDestinations);
	}

	isDestinationRow (row) {
		const iata = row.dataset.iata;
		return typeof iata === 'string' && iata.length;
	}

	isGroupRow (row) {
		const groupType = row.dataset.groupType;
		return typeof groupType === 'string' && groupType.length;
	}

	toggleExpand (groupHeader) {
		if (groupHeader.classList.contains('expanded')) {
			this.collapse(groupHeader);
			this.expandedGroup = null;
		} else {
			const type = groupHeader.dataset.groupType;
			const id = groupHeader.dataset.groupId;
			this.expandedGroup = this.findGroup(type, id);

			this.expand(groupHeader);
		}
	}

	findGroup (type, id) {
		switch (type) {
			case 'country':
				return this.searchResults.countries.find(c => c.id == id);
			case 'region':
				return this.searchResults.regions.find(r => r.id == id);
			case 'nearby_city':
				return this.searchResults.nearby_cities.find(c => c.id == id);
		}

		return null;
	}

	expand (groupHeader) {
		if (this.isMobile()) {
			return;
		}
		// Close all other expanded rows
		const expandedRows = qsa('.expanded', this.scrollWrapper);
		expandedRows.forEach((expandedRow) => {
			this.collapse(expandedRow);
		});
		groupHeader.classList.add('expanded');

		const html = this.expandedGroup.children.map(Handlebars.templates.searchfield_destination).join('');
		const nextGroupHeader = nextElement(groupHeader, '.SearchFieldMulti__group_container');
		if (nextGroupHeader) {
			nextGroupHeader.classList.add('visible');
			nextGroupHeader.innerHTML = html;
			slideDown(nextGroupHeader, 200);
		}
	}

	async collapse (groupHeader) {
		if (this.isMobile()) {
			return;
		}
		groupHeader.classList.remove('expanded');
		const nextGroupHeader = nextElement(groupHeader, '.SearchFieldMulti__group_container');
		if (nextGroupHeader) {
			nextGroupHeader.classList.remove('visible');
			await slideUp(nextGroupHeader, 200);
			nextGroupHeader.innerHTML = '';
		}
	}

	refocus () {
		const x = window.scrollX; const y = window.scrollY;
		this.inputElement.focus();
		window.scrollTo(x, y);
		const len = this.inputElement.value.length;
		this.inputElement.setSelectionRange(len, len);
	}

	render () {
		this.showAutocompleteContainer();
		const previousSearches = this.getStoredUserIatasSpecific(this.inputElementName).reverse();

		// if nothing is being searched (empty searchbox) and there are previous searches, show them
		if (!this.searchText.length && previousSearches.length && !(this.alreadyShowedMostPopular && !this.closedModalSince)) {
			this.renderInitial();
		} else if (!(this.searchResults?.airports.length || this.searchResults?.countries.length || this.searchResults?.regions.length || this.searchResults?.nearby_cities.length)) {
			this.renderNoResults();
		} else if (this.expandedGroup === null) {
			this.renderMainView();
			this.scrollTop();
		} else {
			this.renderExpandedView();
			this.scrollTop();
		}
		this.renderCheckBoxes();
		this.scrollWrapper.querySelector('.SearchFieldMulti__section_header')?.classList?.add?.('SearchFieldMulti__section_header--rounded');
		this.adjustHeight();
	}

	getFromIata () {
		return this.searchBox?.searchFields?.[this.searchFieldIndex]?.from.getCheckedIatas()[0]?.iata;
	}

	renderMainView () {
		const showPriceHunter = false; // ( this.direction === 'TO' );

		const fromIata = this.getFromIata();
		this.alreadyShowedMostPopular = !this.searchText.length
			&& ((this.fromOrToFocus == 'from' && typeof this.fromMostPopular !== 'undefined')
				|| (this.fromOrToFocus == 'to' && typeof this.toMostPopular[fromIata] !== 'undefined'));

		if (this.alreadyShowedMostPopular) {
			this.closedModalSince = false;
		}

		const html = Handlebars.templates.searchfield_search_results({
			showAirports: !!this.searchResults.airports.length,
			airports: this.searchResults.airports,
			showCountries: !!this.searchResults.countries.length,
			countries: this.searchResults.countries,
			showRegions: !!this.searchResults.regions.length,
			regions: this.searchResults.regions,
			nearbyCities: this.searchResults.nearby_cities,
			showNearbyCities: !!this.searchResults.nearby_cities.length,
			translations: this.translations,
			usedFuzzySearch: this.searchResults.usedFuzzySearch,
			isMobile: this.isMobile(),
			isDesktop: this.isDesktop(),
			showPriceHunter
		});

		this.scrollWrapper.innerHTML = html;
	}

	renderExpandedView () {
		const priceHunterLink = '/' + this.translations.priceHunterLink + '?' + (this.expandedGroup.type === 'country' ? 'country=' : 'region=') + this.expandedGroup.id;
		const showPriceHunter = false; // ( this.direction === 'TO' );

		const html = Handlebars.templates.searchfield_expanded_group({
			groupHeader: this.expandedGroup.name,
			groupType: this.expandedGroup.type.toUpperCase(),
			airports: this.expandedGroup.children,
			translations: this.translations,
			showPriceHunter,
			priceHunterLink
		});
		this.scrollWrapper.innerHTML = html;
	}

	renderInitial () {
		const showPriceHunter = false; // ( this.direction === 'TO' );

		if (!showPriceHunter && !this.initialAirports.length) {
			this.hideAutocompleteContainer();
			return;
		}

		this.showAutocompleteContainer();
		const html = Handlebars.templates.searchfield_initial({
			airports: this.initialAirports,
			translations: this.translations,
			showPriceHunter
		});

		this.scrollWrapper.innerHTML = html;
	}

	renderNoResults () {
		const html = Handlebars.templates.searchfield_no_results({
			missingAirportLink: `/${l('global.navigation.informationLink')}?view=missingAirport`,
			showPriceHunter: false, // (this.direction === 'TO'),
			translations: this.translations,
			showMissingAirport: js_params?.module?.searchFieldMulti1?.infopageMissingAirportEnabled
		});
		this.scrollWrapper.innerHTML = html;
	}

	renderCheckBoxes (container = null) {
		if (container == null) {
			container = this.scrollWrapper;
		}
		// Uncheck All
		qsa('.SearchFieldMulti__checkbox', container).forEach((element) => {
			const checkbox = element;
			if (!checkbox) {
				return;
			}
			checkbox.classList.remove('SearchFieldMulti__checkbox--checked');
			checkbox.classList.remove('SearchFieldMulti__checkbox--disabled');
		});
		qsa('.destination', container).forEach((dest) => {
			const iata = dest.dataset.iata;
			const isMetro = dest.dataset.type === 'metro';
			const isChecked = this.checkedIatas.some(checked => checked.iata === iata && checked.isMetro === isMetro);
			if (isChecked) {
				if (isMetro) {
					// check all childrens as well
					const metroWrapper = dest.closest('.SearchFieldMulti__metro_wrapper');
					qsa('.SearchFieldMulti__checkbox', metroWrapper).forEach((element) => {
						element.classList.add('SearchFieldMulti__checkbox--checked');
					});
				} else {
					dest.querySelector('.SearchFieldMulti__checkbox').classList.add('SearchFieldMulti__checkbox--checked');
				}
			}
		});

		if (this.checkedIatas.length > this.iatasLimit && !this.isPriceHunterPage) {
			// disable unchecked
			// const metroWrapper = dest.closest('.SearchFieldMulti__metro_wrapper');
			qsa('.SearchFieldMulti__checkbox:not(.SearchFieldMulti__checkbox--checked)', container).forEach((element) => {
				// element.classList.add('SearchFieldMulti__checkbox--checked');
				element.classList.add('SearchFieldMulti__checkbox--disabled');
			});
			// disable apply btn
			this.fieldModal.querySelectorAll('.SearchFieldMulti__apply_btn').forEach((btn) => {
				btn.classList.add('disabled');
			});
		} else {
			this.fieldModal.querySelectorAll('.SearchFieldMulti__apply_btn').forEach((btn) => {
				btn.classList.remove('disabled');
			});
		}
	}

	renderApply () {
		const limitWarning = this.applyContainer.querySelector('.limit-warning-msg');
		if (!this.checkedIatas.length) {
			this.moduleWrapper.classList.remove('SearchFieldMulti__wrapper--apply-visible');
			this.applyContainer.classList.remove('SearchFieldMulti__apply_container--visible');
			this.applyBtn.classList.remove('visible');
		} else {
			this.moduleWrapper.classList.add('SearchFieldMulti__wrapper--apply-visible');
			this.applyContainer.classList.add('SearchFieldMulti__apply_container--visible');
			this.applyBtn.classList.add('visible');
		}

		if (this.checkedIatas.length > this.iatasLimit && !this.isPriceHunterPage) {
			limitWarning.classList.add('visible');
		} else {
			limitWarning.classList.remove('visible');
		}
		setTimeout(() => this.adjustHeight(), 100);
	}

	getStoredUserIatas () {
		const userIatas = storedItemRead('userIatas');
		if (typeof userIatas !== 'object') {
			// Keep for backward compatibility (encoding removed in 2022)
			const decoded = JSON.parse(Base64.decode(userIatas));
			this.storeUserIatas(decoded);
			return decoded;
		} else if (userIatas) {
			return userIatas;
		}

		return {};
	}


	getStoredUserIatasSpecific () {
		return this.getStoredUserIatas()[this.inputElementName] || [];
	}

	/**
		 * Save user searches to local storage
		 *
		 * @param iatas object of cookie data
		 */
	storeUserIatas (iatas) {
		storedItemCreate('userIatas', iatas);
	}

	appendUserIataToStore (iata, metro) {
		// Only save 5 elements
		const newIatas = this.getStoredUserIatasSpecific().filter(item => item.iata !== iata).slice(-4);
		newIatas.push({ iata: iata, metro: metro });

		const wholeIatas = this.getStoredUserIatas();
		wholeIatas[this.inputElementName] = newIatas;
		this.storeUserIatas(wholeIatas);
	}

	cancel (fromCancelButton = false) {
		if (this.isPriceHunterPage) {
			this.inputElement.value = '';
			this.closeAutocompleteContainer();
			return;
		}

		this.checkedIatas = !fromCancelButton && this.checkedIatas.length <= this.iatasLimit ? this.checkedIatas : Object.assign(this.previousCheckedIatas);

		const event = new Event('change', { bubbles: true });
		this.inputElement.dispatchEvent(event);
		this.inputElement.value = '';
		this.closeAutocompleteContainer();

		if (this.isDefaultSearchBox) {
			this.searchBox.updateLegs();
		}

		this.dispatchIatasChangedEvent();
	}

	apply () {
		const iatasNb = this.checkedIatas.length;

		if (iatasNb > this.iatasLimit && !this.isPriceHunterPage) {
			return;
		}

		this.closeAutocompleteContainer();

		const event = new Event('change', { bubbles: true });
		this.inputElement.dispatchEvent(event);
		this.inputElement.value = '';
		this.inputElement.blur();

		if (this.isDefaultSearchBox) {
			this.searchBox.updateLegs();
		}

		this.dispatchIatasChangedEvent(iatasNb);
	}

	defineReactiveProperty (name, initialValue, updateCallback) {
		let currentValue = initialValue;
		Object.defineProperty(this, name, {
			get () {
				return currentValue;
			},
			set (newValue) {
				currentValue = newValue;
				updateCallback();
			}
		});
	}

	showAutocompleteContainer () {
		if (!this.autocompleteContainerHidden || (this.isPriceHunterPage && document.activeElement !== this.inputElement)) {
			return;
		}
		showElement(this.autocompleteContainer);
		this.moduleWrapper.classList.remove('no-autocomplete');
		this.autocompleteContainerHidden = false;
	}

	getCheckedIatas () {
		return this.checkedIatas;
	}

	setCheckedIatas (checkedIatas) {
		this.checkedIatas = checkedIatas.map(ci => Object.assign({ origin: 'PRESET' }, ci));
		this.dispatchIatasChangedEvent();
	}

	setCheckedIatasOfVariation (checkedIatas, variation) {
		if (variation === this.variation) {
			this.setCheckedIatas(checkedIatas);
		}
	}

	setMaxIatas (limit = null) {
		this.iatasLimit = limit || this.DEFAULT_IATAS_LIMIT;
		this.isMultiModeAllowed = (limit !== 1);
		this.truncateCheckedIatas();
		if (this.isMultiModeAllowed) {
			// Update connected searchFields accordingly
			this.dispatchIatasChangedEvent(this.checkedIatas.length);
		}
	}

	updateMaxIatasBasedOnOppositeField (oppositeFieldIatasNb = null) {
		if (this.isMultiModeAllowed) {
			// Calculate how many iatas this instance can add based on it's siblings checked iatas
			const remainingIatasAllowed = (oppositeFieldIatasNb) ? Math.floor(this.DEFAULT_IATAS_LIMIT / oppositeFieldIatasNb) : this.DEFAULT_IATAS_LIMIT;
			// Ensure that the number of iatas doesn't excede the current iataLimit
			this.iatasLimit = remainingIatasAllowed;
		} else {
			this.iatasLimit = 1;
		}
	}

	getIatasChangedEventName (direction) {
		return `on${direction}IatasChanged`;
	}

	getSetIatasEventName (direction) {
		return `set${direction}Iatas`;
	}

	dispatchIatasChangedEvent (iatasNb) {
		const eventToDispatch = this.getIatasChangedEventName(this.direction);
		const nb = (iatasNb) ? iatasNb : this.checkedIatas.length;
		EventDispatcher.dispatch(eventToDispatch, { origin: this.direction, iatasNb: nb, inputElement: this.inputElement });
	}

	truncateCheckedIatas () {
		if (this.checkedIatas.length > this.iatasLimit && !this.isPriceHunterPage) {
			this.checkedIatas = this.checkedIatas.slice(0, this.iatasLimit);
		}
	}

	getKeystrokesCount () {
		return this.keystrokesCount;
	}

	resetKeystrokesCount () {
		this.keystrokesCount = 0;
	}

	hideAutocompleteContainer () {
		if (this.autocomleteContainerHidden) {
			return;
		}
		hideElement(this.autocompleteContainer);
		this.moduleWrapper.classList.add('no-autocomplete');
		this.autocompleteContainerHidden = true;
	}

	closeAutocompleteContainer () {
		this.hideAutocompleteContainer();
		this.closeModal();
		this.deactivate();
	}

	// Possible classes: REGION, COUNTRY, AIRPORT, NEARBY_CITY, NEARBY_CHECKED, FUZZY, HISTORY, PRESET
	classifyDestination (targetDest) {
		if (this.searchResults && this.searchResults.usedFuzzySearch) {
			return 'FUZZY';
		}

		return targetDest.closest('.SearchFieldMulti__section_container').dataset.type;
	}

	scrollTop () {
		this.scrollWrapper.scrollTo({ top: 0 });
	}

	pageScrollTop (elem, offsetTop) {
		this.searchBox.initialScrollPos = window.scrollY;
		const elemOffset = elem.getBoundingClientRect().top + window.scrollY - offsetTop;

		if (this.isMobile()) {
			window.scrollTo({ top: elemOffset });
		}
	}

	trackCheckedIata (origin) {
		trackEvent.searchFieldIataSelected({
			segment: 'B',
			origin,
			keystrokes: this.getKeystrokesCount(),
			bound: this.direction
		});
		this.resetKeystrokesCount();
	}

	startKeyboardDetect (callback) {
		window.clearInterval(this.keyboardDetectInterval);
		let currentHeight = window.innerHeight;
		this.keyboardDetectInterval = window.setInterval(() => {
			if (currentHeight != window.innerHeight) {
				currentHeight = window.innerHeight;
				callback();
			}
		}, 100);
	}

	// Adjust height responsively based on whether the keyboard is active or not
	adjustHeight () {
		if (this.isDesktop()) {
			return;
		}

		// @todo fix tablet
		const MIN_HEIGHT = 250;
		const DEFAULT_HEIGHT = '55vh';
		if (this.applyContainer.classList.contains(('SearchFieldMulti__apply_container--visible'))) {
			const offset = getOffset(this.applyContainer).top + this.applyContainer.clientHeight - scrollY;
			const delta = window.innerHeight - offset;
			const currentMaxHeight = this.scrollWrapper.clientHeight;
			const marginBottom = 50;
			const newMaxHeight = Math.max(MIN_HEIGHT, currentMaxHeight + delta - marginBottom);
			this.scrollWrapper.style.maxHeight = newMaxHeight + 'px';
		} else {
			this.scrollWrapper.style.maxHeight = DEFAULT_HEIGHT;
		}
	}

	enableBodyScrolling () {
		document.body.style.overflow = '';
		// document.removeEventListener('touchmove', this.touchMoveHandler);
	}

	disableBodyScrolling () {
		document.body.style.overflow = 'hidden';
		// document.addEventListener('touchmove', this.touchMoveHandler, { passive : false });
		// document.addEventListener('scroll', this.touchMoveHandler, { passive : false });
	}

	openModal (elem) {
		if (!this.fieldModalHidden) {
			return;
		}

		this.pageScrollTop(elem, 40);
		this.fieldModal.classList.add('SearchFieldMulti__modal--open');
		this.fieldModalHidden = false;
		EventDispatcher.dispatch('searchFieldMulti.modalOpened', true);
	}

	closeModal () {
		this.closedModalSince = true;
		if (!this.fieldModalHidden) {
			this.fieldModal.classList.remove('SearchFieldMulti__modal--open');
			EventDispatcher.dispatch('searchFieldMulti.modalOpened', false);
		}
		this.fieldModalHidden = true;
	}

	activate () {
		if (this.isActive) {
			return;
		}

		this.isActive = true;
		this.firstRender = true;
		this.previousCheckedIatas = Object.assign(this.checkedIatas);
		this.moduleWrapper.classList.add('SearchFieldMulti__wrapper--active');
		this.searchFieldMultiClickHandler = ({ target }) => {
			const nativeWrapper = this.moduleWrapper;
			if (hasAncestor(target, nativeWrapper)) {
				this.moduleWrapper.classList.add('multi-mode');
				return;
			}

			// Click is outside -> cansel and hide
			this.cancel();
		};
		this.searchFieldMultiClickHandler = this.searchFieldMultiClickHandler.bind(this);
		setTimeout(() => {
			document.body.addEventListener('click', this.searchFieldMultiClickHandler);
		}, 250);
		if (!this.isDesktop()) {
			this.startKeyboardDetect(() => this.adjustHeight());
			this.disableBodyScrolling();
		}
		if (!isShowresultPage()) {
			hideElement('.abSearchBox__switcher');
		}

		if (this.isDefaultSearchBox) {
			this.searchBoxShowOverlay();
			this.searchBoxFocus();
		}

		if (this.isMultiModeAllowed) {
			this.scrollFieldToEnd();
		}
	}

	deactivate () {
		if (this.isDefaultSearchBox) {
			this.searchBoxBlur();
		}
		document.body.removeEventListener('click', this.searchFieldMultiClickHandler);
		window.clearInterval(this.keyboardDetectInterval);
		this.enableBodyScrolling();
		this.isActive = false;
		this.expandedGroup = null;
		if (!isShowresultPage()) {
			showElement('.abSearchBox__switcher');
		}

		this.moduleWrapper.classList.remove('SearchFieldMulti__wrapper--active');
		this.moduleWrapper.classList.remove('multi-mode');
		this.inputElement.value = '';
		this.searchText = '';
		if (document.getElementsByClassName('SearchFieldMulti__wrapper--active').length == 0) {
			if (this.isDefaultSearchBox) {
				this.searchBoxHideOverlay();
			}
		}
		this.applyBtn.classList.remove('visible');
		this.scrollFieldToStart();
	}

	scrollFieldToStart () {
		this.fieldScroller.scrollTo({ left: 0, behavior: 'smooth' });
	}

	scrollFieldToEnd () {
		if (!this.isInputScrollingEnabled()) {
			return;
		}
		const arbitraryLargeValue = 1000;
		this.fieldScroller.scrollTo({ left: arbitraryLargeValue, behavior: 'smooth' });
	}

	isMobile () {
		return isMobileView();
	}

	isDesktop () {
		return isDesktopView();
	}

	// Temporary methods to avoid conflicts with searchbox module for now
	// @TODO move to searchbox
	searchBoxFocus () {
		const style = 'abSearchBox__row--focus-' + this.direction.toLowerCase();
		const element = this.moduleWrapper.closest('.abSearchBox__row');
		if (element) {
			element.classList.add(style);
		}
	}

	searchBoxBlur () {
		const style = 'abSearchBox__row--focus-' + this.direction.toLowerCase();
		const element = this.moduleWrapper.closest('.abSearchBox__row');
		if (element) {
			element.classList.remove(style);
		}
	}

	searchBoxShowOverlay () {
		if (!window.searchBox) {
			return;
		}
		window.searchBox.html.classList.add('abSearchBox--show-overlay');
	}

	searchBoxHideOverlay () {
		if (!window.searchBox) {
			return;
		}
		window.searchBox.html.classList.remove('abSearchBox--show-overlay');
	}

	isEmpty () {
		return !this.checkedIatas.length;
	}
}
